/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap'); */

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

body {
  width: 100%;
  min-width: 100vw;
  min-height: 100vh;
  font-family: 'Poppins';
  overflow-x: hidden;
  height: 100%;
  /*Firefox scrollbar*/
  scrollbar-color: var(--primary) #ededed;
}

#root {
  height: 100%;
  min-height: 100vh;
}

@media screen and (max-width: 1920px) {
  html {
    height: 100%;
    min-height: 100vh;
  }

  .drawer-zoom {
    zoom: 0.9;
  }
}

@media screen and (max-width: 1800px) {
  html {
    height: 100%;
    min-height: 100vh;
  }

  .drawer-zoom {
    zoom: 0.8;
  }
}

@media screen and (max-width: 1706px) {
  html {
    height: 100%;
    min-height: 100vh;
  }

  .drawer-zoom {
    zoom: 0.8;
  }
}

@media screen and (max-width: 1537px) {
  html {
    height: 100%;
    min-height: 100vh;
  }

  .drawer-zoom {
    zoom: 0.75;
  }
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ededed;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
}
